<template>

    <div class="mini_game_bet">
        <div class="mini_game">
            <div class="m1 dmr">
                <div class="mini_fram">
                    <leisure-game-ifram-view>
                        <iframe id="donghang-gamefram" scrolling="no" frameborder="0" allowtransparency="true"
                                src="http://ntry.com/scores/powerball/live.php"></iframe>
                    </leisure-game-ifram-view>
                </div>

            </div>
            <div class="m2">
                <div class="game_tab">
                    <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
                    <leisure-timer-comp
                            ref="ltimer"
                            :leisure-game-info="leisureGameInfo"
                            @timerStop="timerStop" @refreshTimer="refreshTimer">
                        <span slot="title" class="text-orange"><i class="fa fa-star"></i>파워볼 </span>
                    </leisure-timer-comp>

                    <!--선택부분-->
                    <div class="mini_bet_box">
                        <div class="mg">
                            <span class="t text-skyblue">파워볼 홀/짝 , 오버/언더</span>
                            <div class="mg_btn b4 bg-blue"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_POWERBALL_ODD_EVEN && selectedInfo.selectedWay === 1}"
                                 @click="selected(leisureConst.P_POWERBALL_ODD_EVEN,leisureGameInfo.leisureGame.id,1, poddevenConfig.odds1, poddevenConfig,'파워볼 홀')">
                                <div class="r rblue">
                                    <span class="n">홀</span>
                                    <span class="b">{{ poddevenConfig.odds1}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b4 bg-red"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_POWERBALL_ODD_EVEN && selectedInfo.selectedWay === 2}"
                                 @click="selected(leisureConst.P_POWERBALL_ODD_EVEN,leisureGameInfo.leisureGame.id,2,  poddevenConfig.odds2, poddevenConfig,'파워볼 짝')">
                                <div class="r rred">
                                    <span class="n">짝</span>
                                    <span class="b">{{ poddevenConfig.odds2}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b4  bg-red"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_POWERBALL_OVERUNDER && selectedInfo.selectedWay === 1}"
                                 @click="selected(leisureConst.P_POWERBALL_OVERUNDER,leisureGameInfo.leisureGame.id,1, poverunderConfig.odds1, poverunderConfig,'파워볼 오버')">
                                <div class="r rred">
                                    <span class="n">오버</span>
                                    <span class="b">{{poverunderConfig.odds1}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b4  bg-blue"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_POWERBALL_OVERUNDER && selectedInfo.selectedWay === 2}"
                                 @click="selected(leisureConst.P_POWERBALL_OVERUNDER,leisureGameInfo.leisureGame.id,2,  poverunderConfig.odds2, poverunderConfig,'파워볼 언더')">
                                <div class="r rblue">
                                    <span class="n">언더</span>
                                    <span class="b">{{poverunderConfig.odds2}}</span>
                                </div>
                            </div>
                        </div>
                        <!--                    <div class="mg">-->
                        <!--                        <span class="t">파워볼 구간(A~D)</span>-->
                        <!--                        <div class="mg_btn b4"-->
                        <!--                             :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_POWERBALL_INTERVAL && selectedInfo.selectedWay === 1}"-->
                        <!--                             @click="selected(leisureConst.P_POWERBALL_INTERVAL,leisureGameInfo.leisureGame.id,1, psectionConfig.odds1, psectionConfig,'파워볼 구간 A')">-->
                        <!--                            <div class="r rblue">-->
                        <!--                                <span class="n">A</span>-->
                        <!--                                <span class="b">{{ psectionConfig.odds1}}</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="mg_btn b4"-->
                        <!--                             :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_POWERBALL_INTERVAL && selectedInfo.selectedWay === 2}"-->
                        <!--                             @click="selected(leisureConst.P_POWERBALL_INTERVAL,leisureGameInfo.leisureGame.id,2,  psectionConfig.odds2, psectionConfig,'파워볼 구간 B')">-->
                        <!--                            <div class="r rblue">-->
                        <!--                                <span class="n">B</span>-->
                        <!--                                <span class="b">{{ psectionConfig.odds2}}</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="mg_btn b4"-->
                        <!--                             :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_POWERBALL_INTERVAL && selectedInfo.selectedWay === 3}"-->
                        <!--                             @click="selected(leisureConst.P_POWERBALL_INTERVAL,leisureGameInfo.leisureGame.id,3,  psectionConfig.odds3, psectionConfig,'파워볼 구간 C')">-->
                        <!--                            <div class="r rblue">-->
                        <!--                                <span class="n">C</span>-->
                        <!--                                <span class="b">{{ psectionConfig.odds3}}</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="mg_btn b4"-->
                        <!--                             :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_POWERBALL_INTERVAL && selectedInfo.selectedWay === 4}"-->
                        <!--                             @click="selected(leisureConst.P_POWERBALL_INTERVAL,leisureGameInfo.leisureGame.id,4,  psectionConfig.odds4, psectionConfig,'파워볼 구간 D')">-->
                        <!--                            <div class="r rblue">-->
                        <!--                                <span class="n">D</span>-->
                        <!--                                <span class="b">{{ psectionConfig.odds4}}</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                    </div>-->
                        <div class="mg">
                            <span class="t text-skyblue">파워볼 홀짝+오버언더</span>
                            <div class="mg_btn b4  bg-blue"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_POWERBALL_ODDEVEN_UNDEROVER && selectedInfo.selectedWay === 1}"
                                 @click="selected(leisureConst.P_POWERBALL_ODDEVEN_UNDEROVER,leisureGameInfo.leisureGame.id,1, poddevenoverunderConfig.odds1, poddevenoverunderConfig,'파워볼 홀오버')">
                                <div class="r rblue">
                                    <span class="n">홀오버</span>
                                    <span class="b">{{ poddevenoverunderConfig.odds1}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b4  bg-red"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_POWERBALL_ODDEVEN_UNDEROVER && selectedInfo.selectedWay === 2}"
                                 @click="selected(leisureConst.P_POWERBALL_ODDEVEN_UNDEROVER,leisureGameInfo.leisureGame.id,2,  poddevenoverunderConfig.odds2, poddevenoverunderConfig,'파워볼 홀언더')">
                                <div class="r rblue">
                                    <span class="n">홀언더</span>
                                    <span class="b">{{ poddevenoverunderConfig.odds2}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b4  bg-blue"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_POWERBALL_ODDEVEN_UNDEROVER && selectedInfo.selectedWay === 3}"
                                 @click="selected(leisureConst.P_POWERBALL_ODDEVEN_UNDEROVER,leisureGameInfo.leisureGame.id,3, poddevenoverunderConfig.odds3, poddevenoverunderConfig,'파워볼 짝오버')">
                                <div class="r rred">
                                    <span class="n">짝오버</span>
                                    <span class="b">{{ poddevenoverunderConfig.odds3}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b4  bg-red"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_POWERBALL_ODDEVEN_UNDEROVER && selectedInfo.selectedWay === 4}"
                                 @click="selected(leisureConst.P_POWERBALL_ODDEVEN_UNDEROVER,leisureGameInfo.leisureGame.id,4, poddevenoverunderConfig.odds4, poddevenoverunderConfig,'파워볼 짝언더')">
                                <div class="r rred">
                                    <span class="n">짝언더</span>
                                    <span class="b">{{ poddevenoverunderConfig.odds4}}</span>
                                </div>
                            </div>
                        </div>

                        <!--일반볼-->
                        <div class="mg">
                            <span class="t text-white">일반볼 홀/짝 , 오버/언더</span>
                            <div class="mg_btn b4  bg-blue"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_ODD_EVEN && selectedInfo.selectedWay === 1}"
                                 @click="selected(leisureConst.P_NORMALBALL_ODD_EVEN,leisureGameInfo.leisureGame.id,1, noddevenConfig.odds1, noddevenConfig,'일반볼 홀')">
                                <div class="r rblue">
                                    <span class="n">홀</span>
                                    <span class="b">{{ noddevenConfig.odds1}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b4  bg-red"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_ODD_EVEN && selectedInfo.selectedWay === 2}"
                                 @click="selected(leisureConst.P_NORMALBALL_ODD_EVEN,leisureGameInfo.leisureGame.id,2,  noddevenConfig.odds2, noddevenConfig,'일반볼 짝')">
                                <div class="r rred">
                                    <span class="n">짝</span>
                                    <span class="b">{{ noddevenConfig.odds2}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b4  bg-red"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_OVERUNDER && selectedInfo.selectedWay === 1}"
                                 @click="selected(leisureConst.P_NORMALBALL_OVERUNDER,leisureGameInfo.leisureGame.id,1, noverunderConfig.odds1, noverunderConfig,'일반볼 오버')">
                                <div class="r rred">
                                    <span class="n">오버</span>
                                    <span class="b">{{ noverunderConfig.odds1}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b4  bg-blue"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_OVERUNDER && selectedInfo.selectedWay === 2}"
                                 @click="selected(leisureConst.P_NORMALBALL_OVERUNDER,leisureGameInfo.leisureGame.id,2,  noverunderConfig.odds2, noverunderConfig,'일반볼 언더')">
                                <div class="r rblue">
                                    <span class="n">언더</span>
                                    <span class="b">{{ noverunderConfig.odds2}}</span>
                                </div>
                            </div>
                        </div>

                        <!--                    <div class="mg">-->
                        <!--                        <span class="t text-white">일반볼 대,중,소</span>-->
                        <!--                        <div class="mg_btn b3"-->
                        <!--                             :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_BMS && selectedInfo.selectedWay === 1}"-->
                        <!--                             @click="selected(leisureConst.P_NORMALBALL_BMS,leisureGameInfo.leisureGame.id,1, nbmsConfig.odds1, nbmsConfig,'일반볼 대')">-->
                        <!--                            <div class="r rblue">-->
                        <!--                                <span class="n">대</span>-->
                        <!--                                <span class="b">{{ nbmsConfig.odds1}}</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="mg_btn b3"-->
                        <!--                             :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_BMS && selectedInfo.selectedWay === 2}"-->
                        <!--                             @click="selected(leisureConst.P_NORMALBALL_BMS,leisureGameInfo.leisureGame.id,2, nbmsConfig.odds2, nbmsConfig,'일반볼 중')">-->
                        <!--                            <div class="r rred">-->
                        <!--                                <span class="n">중</span>-->
                        <!--                                <span class="b">{{ nbmsConfig.odds2}}</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="mg_btn b3"-->
                        <!--                             :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_BMS && selectedInfo.selectedWay === 3}"-->
                        <!--                             @click="selected(leisureConst.P_NORMALBALL_BMS,leisureGameInfo.leisureGame.id,3, nbmsConfig.odds3, nbmsConfig,'일반볼 소')">-->
                        <!--                            <div class="r rblue">-->
                        <!--                                <span class="n">소</span>-->
                        <!--                                <span class="b">{{ nbmsConfig.odds3}}</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                    </div>-->
                        <div class="mg">
                            <span class="t text-white">일반볼 홀짝+오버언더</span>
                            <div class="mg_btn b4  bg-blue"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_ODDEVEN_UNDEROVER && selectedInfo.selectedWay === 1}"
                                 @click="selected(leisureConst.P_NORMALBALL_ODDEVEN_UNDEROVER,leisureGameInfo.leisureGame.id,1, noddevenoverunderConfig.odds1, noddevenoverunderConfig,'일반볼 홀오버')">
                                <div class="r rblue">
                                    <span class="n">홀오버</span>
                                    <span class="b">{{ noddevenoverunderConfig.odds1}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b4  bg-red"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_ODDEVEN_UNDEROVER && selectedInfo.selectedWay === 2}"
                                 @click="selected(leisureConst.P_NORMALBALL_ODDEVEN_UNDEROVER,leisureGameInfo.leisureGame.id,2, noddevenoverunderConfig.odds2, noddevenoverunderConfig,'일반볼 홀언더')">
                                <div class="r rblue">
                                    <span class="n">홀언더</span>
                                    <span class="b">{{ noddevenoverunderConfig.odds2}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b4  bg-blue"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_ODDEVEN_UNDEROVER && selectedInfo.selectedWay === 3}"
                                 @click="selected(leisureConst.P_NORMALBALL_ODDEVEN_UNDEROVER,leisureGameInfo.leisureGame.id,3, noddevenoverunderConfig.odds3, noddevenoverunderConfig,'일반볼 짝오버')">
                                <div class="r rred">
                                    <span class="n">짝오버</span>
                                    <span class="b">{{ noddevenoverunderConfig.odds3}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b4 bg-red"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_ODDEVEN_UNDEROVER && selectedInfo.selectedWay === 4}"
                                 @click="selected(leisureConst.P_NORMALBALL_ODDEVEN_UNDEROVER,leisureGameInfo.leisureGame.id,4, noddevenoverunderConfig.odds4, noddevenoverunderConfig,'일반볼 짝언더')">
                                <div class="r rred">
                                    <span class="n">짝언더</span>
                                    <span class="b">{{ noddevenoverunderConfig.odds4}}</span>
                                </div>
                            </div>
                        </div>

                        <!--                    <div class="mg">-->
                        <!--                        <span class="t text-white">일반볼 홀짝+대중소</span>-->
                        <!--                        <div class="mg_btn b3"-->
                        <!--                             :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_ODDEVEN_BMS && selectedInfo.selectedWay === 1}"-->
                        <!--                             @click="selected(leisureConst.P_NORMALBALL_ODDEVEN_BMS,leisureGameInfo.leisureGame.id,1, noddevenbmsConfig.odds1, noddevenbmsConfig,'일반볼 홀대')">-->
                        <!--                            <div class="r rblue">-->
                        <!--                                <span class="n">홀대</span>-->
                        <!--                                <span class="b">{{ noddevenbmsConfig.odds1}}</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="mg_btn b3"-->
                        <!--                             :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_ODDEVEN_BMS && selectedInfo.selectedWay === 2}"-->
                        <!--                             @click="selected(leisureConst.P_NORMALBALL_ODDEVEN_BMS,leisureGameInfo.leisureGame.id,2, noddevenbmsConfig.odds2, noddevenbmsConfig,'일반볼 홀중')">-->
                        <!--                            <div class="r rred">-->
                        <!--                                <span class="n">홀중</span>-->
                        <!--                                <span class="b">{{ noddevenbmsConfig.odds2}}</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="mg_btn b3"-->
                        <!--                             :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_ODDEVEN_BMS && selectedInfo.selectedWay === 3}"-->
                        <!--                             @click="selected(leisureConst.P_NORMALBALL_ODDEVEN_BMS,leisureGameInfo.leisureGame.id,3, noddevenbmsConfig.odds3, noddevenbmsConfig,'일반볼 홀소')">-->
                        <!--                            <div class="r rblue">-->
                        <!--                                <span class="n">홀소</span>-->
                        <!--                                <span class="b">{{ noddevenbmsConfig.odds3}}</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="mg_btn b3 mt5"-->
                        <!--                             :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_ODDEVEN_BMS && selectedInfo.selectedWay === 4}"-->
                        <!--                             @click="selected(leisureConst.P_NORMALBALL_ODDEVEN_BMS,leisureGameInfo.leisureGame.id,4, noddevenbmsConfig.odds4, noddevenbmsConfig,'일반볼 짝대')">-->
                        <!--                            <div class="r rblue">-->
                        <!--                                <span class="n">짝대</span>-->
                        <!--                                <span class="b">{{ noddevenbmsConfig.odds4}}</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="mg_btn b3 mt5"-->
                        <!--                             :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_ODDEVEN_BMS && selectedInfo.selectedWay === 5}"-->
                        <!--                             @click="selected(leisureConst.P_NORMALBALL_ODDEVEN_BMS,leisureGameInfo.leisureGame.id,5, noddevenbmsConfig.odds5, noddevenbmsConfig,'일반볼 짝중')">-->
                        <!--                            <div class="r rred">-->
                        <!--                                <span class="n">짝중</span>-->
                        <!--                                <span class="b">{{ noddevenbmsConfig.odds5}}</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="mg_btn b3 mt5"-->
                        <!--                             :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.P_NORMALBALL_ODDEVEN_BMS && selectedInfo.selectedWay === 6}"-->
                        <!--                             @click="selected(leisureConst.P_NORMALBALL_ODDEVEN_BMS,leisureGameInfo.leisureGame.id,6, noddevenbmsConfig.odds6, noddevenbmsConfig,'일반볼 짝소')">-->
                        <!--                            <div class="r rblue">-->
                        <!--                                <span class="n">짝소</span>-->
                        <!--                                <span class="b">{{ noddevenbmsConfig.odds6}}</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                    </div>-->
                    </div>
                </div>
            </div>

            <div class="m3">
                <!--파워볼 베팅카트-->
                <leisure-bet-cart-comp
                        ref="betCart" :kind="kind"
                        :selected-info="selectedInfo">
                    파워볼
                </leisure-bet-cart-comp>

                <!--최근 배팅내역-->
                <leisure-lately-bet-list-comp
                        ref="latelyBetList"
                        :kind="kind"></leisure-lately-bet-list-comp>

            </div>


        </div>
    </div> <!--./mini_game_bet end--->

</template>

<script>
    import SubTitle from "../../../components/SubTitle";
    import LeisureTimerComp from "../../../components/leisuregame/LeisureTimerComp";
    import LeisureBetCartComp from "../../../components/leisuregame/LeisureBetCartComp";
    import leisureConst from "../../../common/leisureConst";
    import sportsConst from "../../../common/sportsConst";
    import {getLeisureGameAttributeConfig, getOnBetLeisureGames} from "../../../network/leisureRequest";
    import LeisureBetMasker from "../../../components/leisuregame/LeisureBetMasker";
    import {leisureGameMixin} from "../../../common/mixin";
    import LeisureLatelyBetListComp from "../../../components/leisuregame/LeisureLatelyBetListComp";
    import LeisureGameView from "../../../components/leisuregame/LeisureGameView";
    import LeisureGameLinks from "../LeisureGameLinks";
    import LeisureGameIframView from "../LeisureGameIframView";
    import LeisureCompGameLinks from "../LeisureCompDonghangGameLinks";
    import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "../../../store/mutation-types";

    export default {

        name: "Powerball",
        mixins: [leisureGameMixin],
        components: {
            LeisureCompGameLinks,
            LeisureGameIframView,
            LeisureGameLinks,
            LeisureGameView,
            LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp, SubTitle
        },
        data() {
            return {
                kind: leisureConst.LEISURE_KIND_POWERBALL,
            }
        },
        methods: {
            initLeisureGameArttributeConfig() {
                getLeisureGameAttributeConfig().then(res => {
                    if (res.data.success) {
                        this.attrConfig = res.data.data


                        //배당설정
                        //파워볼
                        this.poddevenConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.P_POWERBALL_ODD_EVEN)
                        })
                        this.poverunderConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.P_POWERBALL_OVERUNDER)
                        })
                        this.psectionConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.P_POWERBALL_INTERVAL)
                        })
                        this.pnumberConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.P_POWERBALL_NUMBER)
                        })
                        this.poddevenoverunderConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.P_POWERBALL_ODDEVEN_UNDEROVER)
                        })
                        //일반볼
                        this.noddevenConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.P_NORMALBALL_ODD_EVEN)
                        })
                        this.noverunderConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.P_NORMALBALL_OVERUNDER)
                        })
                        this.nsectionConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.P_NORMALBALL_INTERVAL)
                        })
                        this.nbmsConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.P_NORMALBALL_BMS)
                        })
                        this.noddevenoverunderConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.P_NORMALBALL_ODDEVEN_UNDEROVER)
                        })
                        this.noddevenbmsConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.P_NORMALBALL_ODDEVEN_BMS)
                        })

                    }
                })
            }
        },
        created() {
            this.initKindConfig(this.kind);
            this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP, leisureConst.LEISURE_COMP_DONGHANG);
            this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID, this.kind);
        }
    }
</script>

<style scoped>

</style>